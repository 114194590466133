import React, { Component } from "react";
// import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";

// const SocialShare = [
//     { Social: <FaFacebookF />, link: 'https://www.facebook.com/' },
//     { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/' },
//     { Social: <FaInstagram />, link: 'https://www.instagram.com/' },
//     { Social: <FaTwitter />, link: 'https://twitter.com/' },
// ]
class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                <footer className="footer-area">
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0">
                            <div className="col-lg-6">
                                <div className="footer-left">
                                    <div className="inner">
                                        {/* <span>льешь траффик?</span> */}
                                        <h2>Do you want to work <br /> with us?</h2>
                                        <a className="rn-button-style--2" href="/cooperation">
                                            <span>Cooperate</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer-right" data-dark-overlay="6">
                                    <div className="row">
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-12 col-sm-12 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4>Contacts for communication</h4>
                                                <ul className="ft-link">
                                                    {/* <li><a href="https://t.me/inbguru" target="_blank" rel="noopener noreferrer">t.me/inbguru</a></li> */}
                                                    <li><a href="mailto:hello@inb.bio" rel="noopener noreferrer">hello@inb.bio</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/*<div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">*/}
                                        {/*    <div className="footer-link">*/}
                                        {/*        <h4>Номера телефонов</h4>*/}
                                        {/*        <ul className="ft-link">*/}
                                        {/*            <li><a href="tel:+38 050 526 83 24">+38 050 526 83 24</a> - Рожден</li>*/}
                                        {/*            <li><a href="tel:+38 095 425 83 34">+38 095 425 83 34</a> - Марк</li>*/}
                                        {/*            <li><a href="tel:+38 095 425 83 51">+38 095 425 83 51</a> - Руслан</li>*/}
                                        {/*        </ul>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/* End Single Widget  */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;