import React from "react";

import "./styles.scss";

export const Advantages = ({ setPopup, popup }) => {
  return (
    <section id="adwantages" className="adwantages">
      <div className="adwantages__container _container">
        <div className="adwantages__amount">
          <div className="adwantages__text">
            <h1>Наши преимущества.</h1>
            <p>
              Представьте, что стоимость лида составляет 15 долларов. <br />
              <b>Что будет дальше:</b>
            </p>
          </div>
          <div className="adwantages__cards">
            <div className="adwantages__card adwantages__card-white">
              <div className="adwantages__card-logo">AN</div>
              <div className="adwantages__card-text">
                <h6>
                  <b>12$</b> / 15$ -3$
                </h6>
                <p>
                  Партнерская сеть <b>берёт за себя 3$</b> и всего 12$ идет к медиабайеру
                </p>
              </div>
            </div>
            <div className="adwantages__card adwantages__card-green">
              <img
                src="/assets/images/logo/logo.png"
                alt=""
                className="adwantages__card-imglogo"
              />
              <div className="adwantages__card-text">
                <h6>
                  <b>15$</b> / 15$ -0$
                </h6>
                <p>
                  Поскольку <b>мы не берем комиссию</b>, медиабайер получает полные 15$
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="adwantages__lists">
          <div className="adwantages__inb">
            <h2 className="adwantages__inb-title">INB</h2>
            <div className="adwantages__inb-list">
              <h6>Что включено:</h6>
              <ul>
                <li>
                  <svg
                    width="18"
                    height="14"
                    viewBox="0 0 18 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.5057 0.744257C18.1648 1.40327 18.1648 2.47173 17.5057 3.13074L7.38074 13.2557C6.72173 13.9148 5.65327 13.9148 4.99426 13.2557L0.494257 8.75574C-0.164752 8.09673 -0.164752 7.02827 0.494257 6.36926C1.15327 5.71025 2.22173 5.71025 2.88074 6.36926L6.1875 9.67601L15.1193 0.744257C15.7783 0.0852476 16.8467 0.0852476 17.5057 0.744257Z"
                      fill="#FFF"
                    />
                  </svg>
                  <span>Уникальные гео</span>
                </li>
                <li>
                  <svg
                    width="18"
                    height="14"
                    viewBox="0 0 18 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.5057 0.744257C18.1648 1.40327 18.1648 2.47173 17.5057 3.13074L7.38074 13.2557C6.72173 13.9148 5.65327 13.9148 4.99426 13.2557L0.494257 8.75574C-0.164752 8.09673 -0.164752 7.02827 0.494257 6.36926C1.15327 5.71025 2.22173 5.71025 2.88074 6.36926L6.1875 9.67601L15.1193 0.744257C15.7783 0.0852476 16.8467 0.0852476 17.5057 0.744257Z"
                      fill="#FFF"
                    />
                  </svg>
                  <span>Уникальные оферы</span>
                </li>
                <li>
                  <svg
                    width="18"
                    height="14"
                    viewBox="0 0 18 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.5057 0.744257C18.1648 1.40327 18.1648 2.47173 17.5057 3.13074L7.38074 13.2557C6.72173 13.9148 5.65327 13.9148 4.99426 13.2557L0.494257 8.75574C-0.164752 8.09673 -0.164752 7.02827 0.494257 6.36926C1.15327 5.71025 2.22173 5.71025 2.88074 6.36926L6.1875 9.67601L15.1193 0.744257C15.7783 0.0852476 16.8467 0.0852476 17.5057 0.744257Z"
                      fill="#FFF"
                    />
                  </svg>
                  <span>Поддержка 24/7</span>
                </li>
                <li>
                  <svg
                    width="18"
                    height="14"
                    viewBox="0 0 18 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.5057 0.744257C18.1648 1.40327 18.1648 2.47173 17.5057 3.13074L7.38074 13.2557C6.72173 13.9148 5.65327 13.9148 4.99426 13.2557L0.494257 8.75574C-0.164752 8.09673 -0.164752 7.02827 0.494257 6.36926C1.15327 5.71025 2.22173 5.71025 2.88074 6.36926L6.1875 9.67601L15.1193 0.744257C15.7783 0.0852476 16.8467 0.0852476 17.5057 0.744257Z"
                      fill="#FFF"
                    />
                  </svg>
                  <span>Ваш собственный творческий отдел</span>
                </li>
                <li>
                  <svg
                    width="18"
                    height="14"
                    viewBox="0 0 18 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.5057 0.744257C18.1648 1.40327 18.1648 2.47173 17.5057 3.13074L7.38074 13.2557C6.72173 13.9148 5.65327 13.9148 4.99426 13.2557L0.494257 8.75574C-0.164752 8.09673 -0.164752 7.02827 0.494257 6.36926C1.15327 5.71025 2.22173 5.71025 2.88074 6.36926L6.1875 9.67601L15.1193 0.744257C15.7783 0.0852476 16.8467 0.0852476 17.5057 0.744257Z"
                      fill="#FFF"
                    />
                  </svg>
                  <span>Эксклюзивные оферы</span>
                </li>
                <li>
                  <svg
                    width="18"
                    height="14"
                    viewBox="0 0 18 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.5057 0.744257C18.1648 1.40327 18.1648 2.47173 17.5057 3.13074L7.38074 13.2557C6.72173 13.9148 5.65327 13.9148 4.99426 13.2557L0.494257 8.75574C-0.164752 8.09673 -0.164752 7.02827 0.494257 6.36926C1.15327 5.71025 2.22173 5.71025 2.88074 6.36926L6.1875 9.67601L15.1193 0.744257C15.7783 0.0852476 16.8467 0.0852476 17.5057 0.744257Z"
                      fill="#FFF"
                    />
                  </svg>
                  <span>Платные тесты</span>
                </li>
                <li>
                  <svg
                    width="18"
                    height="14"
                    viewBox="0 0 18 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.5057 0.744257C18.1648 1.40327 18.1648 2.47173 17.5057 3.13074L7.38074 13.2557C6.72173 13.9148 5.65327 13.9148 4.99426 13.2557L0.494257 8.75574C-0.164752 8.09673 -0.164752 7.02827 0.494257 6.36926C1.15327 5.71025 2.22173 5.71025 2.88074 6.36926L6.1875 9.67601L15.1193 0.744257C15.7783 0.0852476 16.8467 0.0852476 17.5057 0.744257Z"
                      fill="#FFF"
                    />
                  </svg>
                  <span>Собственная локализация</span>
                </li>
                <li>
                  <svg
                    width="18"
                    height="14"
                    viewBox="0 0 18 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.5057 0.744257C18.1648 1.40327 18.1648 2.47173 17.5057 3.13074L7.38074 13.2557C6.72173 13.9148 5.65327 13.9148 4.99426 13.2557L0.494257 8.75574C-0.164752 8.09673 -0.164752 7.02827 0.494257 6.36926C1.15327 5.71025 2.22173 5.71025 2.88074 6.36926L6.1875 9.67601L15.1193 0.744257C15.7783 0.0852476 16.8467 0.0852476 17.5057 0.744257Z"
                      fill="#FFF"
                    />
                  </svg>
                  <span>Собственные колл-центры</span>
                </li>
              </ul>
            </div>
            <div
              className="adwantages__inb-link"
              onClick={() => setPopup(true)}
            >
              <span>Learn more</span>
            </div>
          </div>
          <div className="adwantages__cpa">
            <h2 className="adwantages__cpa-title">Партнерская сеть</h2>
            <div className="adwantages__cpa-list">
              <h6>Что включено:</h6>
              <ul>
                <li>
                  <svg
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.2193 0.561293C15.7922 1.13423 15.7922 2.06314 15.2193 2.63607L6.41673 11.4386C5.84379 12.0116 4.91488 12.0116 4.34195 11.4386L0.429701 7.52638C-0.143234 6.95344 -0.143234 6.02453 0.429701 5.4516C1.00264 4.87866 1.93155 4.87866 2.50448 5.4516L5.37934 8.32645L13.1445 0.561293C13.7174 -0.0116419 14.6463 -0.0116419 15.2193 0.561293Z"
                      fill="#255742"
                    />
                  </svg>
                  <span>Уникальные гео</span>
                </li>
                <li>
                  <svg
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.2193 0.561293C15.7922 1.13423 15.7922 2.06314 15.2193 2.63607L6.41673 11.4386C5.84379 12.0116 4.91488 12.0116 4.34195 11.4386L0.429701 7.52638C-0.143234 6.95344 -0.143234 6.02453 0.429701 5.4516C1.00264 4.87866 1.93155 4.87866 2.50448 5.4516L5.37934 8.32645L13.1445 0.561293C13.7174 -0.0116419 14.6463 -0.0116419 15.2193 0.561293Z"
                      fill="#255742"
                    />
                  </svg>
                  <span>Уникальные оферы</span>
                </li>
                <li>
                  <svg
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.2193 0.561293C15.7922 1.13423 15.7922 2.06314 15.2193 2.63607L6.41673 11.4386C5.84379 12.0116 4.91488 12.0116 4.34195 11.4386L0.429701 7.52638C-0.143234 6.95344 -0.143234 6.02453 0.429701 5.4516C1.00264 4.87866 1.93155 4.87866 2.50448 5.4516L5.37934 8.32645L13.1445 0.561293C13.7174 -0.0116419 14.6463 -0.0116419 15.2193 0.561293Z"
                      fill="#255742"
                    />
                  </svg>
                  <span>Поддержка 24/7</span>
                </li>
                <li>
                  <svg
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.2193 0.561293C15.7922 1.13423 15.7922 2.06314 15.2193 2.63607L6.41673 11.4386C5.84379 12.0116 4.91488 12.0116 4.34195 11.4386L0.429701 7.52638C-0.143234 6.95344 -0.143234 6.02453 0.429701 5.4516C1.00264 4.87866 1.93155 4.87866 2.50448 5.4516L5.37934 8.32645L13.1445 0.561293C13.7174 -0.0116419 14.6463 -0.0116419 15.2193 0.561293Z"
                      fill="#255742"
                    />
                  </svg>
                  <span>Ваш собственный творческий отдел</span>
                </li>
                <li>
                  <svg
                    width="15"
                    height="4"
                    viewBox="0 0 15 4"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="1.30408"
                      y1="2"
                      x2="13.4755"
                      y2="2"
                      stroke="#1F1F25"
                      strokeWidth="2.60816"
                      strokeLinecap="round"
                    />
                  </svg>
                  <span>Эксклюзивные оферы</span>
                </li>
                <li>
                  <svg
                    width="15"
                    height="4"
                    viewBox="0 0 15 4"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="1.30408"
                      y1="2"
                      x2="13.4755"
                      y2="2"
                      stroke="#1F1F25"
                      strokeWidth="2.60816"
                      strokeLinecap="round"
                    />
                  </svg>
                  <span>Платные тесты</span>
                </li>
                <li>
                  <svg
                    width="15"
                    height="4"
                    viewBox="0 0 15 4"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="1.30408"
                      y1="2"
                      x2="13.4755"
                      y2="2"
                      stroke="#1F1F25"
                      strokeWidth="2.60816"
                      strokeLinecap="round"
                    />
                  </svg>
                  <span>Собственная локализация</span>
                </li>
                <li>
                  <svg
                    width="15"
                    height="4"
                    viewBox="0 0 15 4"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="1.30408"
                      y1="2"
                      x2="13.4755"
                      y2="2"
                      stroke="#1F1F25"
                      strokeWidth="2.60816"
                      strokeLinecap="round"
                    />
                  </svg>
                  <span>Собственные колл-центры</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
