import React from "react";
import { Link } from "react-router-dom";

import "./styles.scss";

export const Sidebar = ({ setSidebar, sidebar, handleAnchor }) => {
  return (
    <div className={`sidebar ${sidebar ? "active" : ""}`}>
      <div className="sidebar__btn" onClick={() => setSidebar(!sidebar)}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L15 15M1.00003 15L8.00003 8L15 1"
            stroke="#255742"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      </div>

      <a
        href=""
        onClick={(e) => {
          e.preventDefault();
          handleAnchor("adwantages");
        }}
        className="sidebar__link"
      >
        Преимущества
      </a>
      <a
        href=""
        onClick={(e) => {
          e.preventDefault();
          handleAnchor("countries");
        }}
        className="sidebar__link"
      >
        Страны
      </a>
      <a
        href="working"
        onClick={(e) => {
          e.preventDefault();
          handleAnchor("working");
        }}
        className="sidebar__link"
      >
        Сотрудничество
      </a>
    </div>
  );
};
