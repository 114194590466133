import React from "react";

import "./styles.scss";

export const Popup = ({ setPopup, popup, handleAnchor }) => {

  return (
    <div className={`popup ${popup ? "active" : ""}`}>
      <div className="popup__content">
        <div className="popup__header">
          <h4>Давай поговорим</h4>
          <div className="popup__close" onClick={() => setPopup(false)}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 5L19 19M5.00003 19L12 12L19 5" stroke="#8E8E8E" strokeWidth="1.5" strokeLinecap="round"/>
            </svg>
          </div>
        </div>
        <form>
          <input type="text" name="name" placeholder="Имя*" />
          <input type="text" name="email" placeholder="Телефон/Телеграмма*" />
          <p>Отправляя форму, вы соглашаетесь с политикой конфиденциальности</p>
          <button>
            <span>Отправить</span>
          </button>
        </form>
      </div>
    </div>
  );
};
