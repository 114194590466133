import React, { useState } from "react";

import "./styles.scss";
import "./mixins.scss";
import { Advantages, Footer, Header, Intro, Popup, Sidebar } from "../component/partners";
import { Helmet } from "react-helmet";

export const Partners = () => {
  const [sidebar, setSidebar] = useState(false);
  const [popup, setPopup] = useState(false);

  const handleAnchor = (url) => {
    console.log(url)
    document.getElementById(url).scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });

    setSidebar(false)
  }

  return (
    <>
      <Helmet pageTitle="Partners page" />
      <Sidebar handleAnchor={handleAnchor} setSidebar={setSidebar} sidebar={sidebar} />
      <Popup setPopup={setPopup} popup={popup} />
      <div className="wrapper-partner">
        <Header handleAnchor={handleAnchor} setSidebar={setSidebar} sidebar={sidebar} setPopup={setPopup} popup={popup} />
        <Intro setPopup={setPopup} popup={popup} />
        <Advantages setPopup={setPopup} popup={popup} />
        <Footer setPopup={setPopup} popup={popup} />
      </div>
    </>
  );
};
